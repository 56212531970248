'use client';
import { useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';

import Loader from '@components/root/Loader';

interface Props {
  onChange: (isVisible: boolean) => any;
  showLoader?: boolean;
  className?: string;
  top?: number;
}

const LazyLoader = ({
  onChange,
  showLoader = true,
  className = '',
  top = 0,
}: Props) => {
  const lazyLoader = useRef(null);
  const intersection = useIntersection(lazyLoader, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  useEffect(() => {
    onChange(intersection?.isIntersecting || false);
  }, [intersection?.isIntersecting]);

  return (
    <div className={`relative ${className}`}>
      <div
        className="absolute z-20 h-[2px] w-[2px]"
        style={{ top: `-${top}px` }}
        ref={lazyLoader}
      />
      {showLoader && <Loader />}
      {!showLoader && <div className="pb-2" />}
    </div>
  );
};

export default LazyLoader;
