'use client';
import { useState } from 'react';

import ArticleList from '../Article/ArticleList';
import ArticleSimpleList from '../Article/ArticleSimpleList';

import { FetchParams, fetchArticlesList } from './fetchArticlesList';

import { LazyLoader } from '@components/Lazy';

type Props = {
  initialPosts: any[],
  startFrom?: number,
  startFromMobile?: number,
  noPosts?: boolean,
  lang?: {
    noResults?: string;
  },
  fetchParams: FetchParams,
  showSimpleView: boolean,
}

function LazyArticles({ initialPosts, noPosts, fetchParams, lang, startFrom = 0, startFromMobile = 0, showSimpleView }: Props) {
  const [posts, setPosts] = useState<any[]>(initialPosts);

  const onChange = async (isVisible: boolean) => {
    if (isVisible && posts.length) {
      const newPosts = await fetchArticlesList({ ...fetchParams, lastDate: posts[posts.length - 1].dateGmt });
      if (!newPosts || newPosts.length === 0) {
        return;
      }

      setPosts(posts.concat(newPosts));
    }
  };

  if (noPosts) {
    return <>
      {lang?.noResults || 'No results found.'}
    </>
  }

  return (
    <>
      {(showSimpleView === true) && (
        <>
          <ArticleSimpleList articles={posts.slice(startFrom)} className="hidden md:grid" />
          <ArticleSimpleList articles={posts.slice(startFromMobile)} className="grid md:hidden" />
        </>
      )}
      {(showSimpleView === false) && (
        <>
          <ArticleList articles={posts.slice(startFrom)} className="hidden md:grid" />
          <ArticleList articles={posts.slice(startFromMobile)} className="grid md:hidden" />
        </>
      )}

      <LazyLoader onChange={onChange}></LazyLoader>
    </>
  );
}

export default LazyArticles;
