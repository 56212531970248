import { ArticleCover } from '@components/Article';

type Props = {
  articles: any[];
  className?: string;
};

const ArticleList = ({ articles, className }: Props) => {
  const isEmpty = articles.length === 0
  return (
    <div
      className={`grid grid-cols-1 gap-10 md:grid-cols-2 ${className}`}
    >
      {!isEmpty &&
        articles.map((article: any, id: number) => (
          <div key={article?.slug}>
            <ArticleCover testId={id} article={article} />
          </div>
        ))}
    </div>
  );
};

export default ArticleList;
