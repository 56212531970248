export class ApiV2Error extends Error {
  public response?: Response;

  constructor(message: string, response?: Response) {
    super(message);
    this.name = 'ApiV2Error';
    this.response = response;
  }
}

export const isApiV2Error = (error: any): error is ApiV2Error => {
  return typeof error['response'] !== 'undefined';
};

export class ApiV2AuthenticationError extends ApiV2Error {
  constructor(message: string, response?: Response) {
    super(message, response);
    this.name = 'ApiV2AuthenticationError';
  }
}

export const isApiV2AuthenticationError = (
  error: any
): error is ApiV2AuthenticationError => {
  return isApiV2Error(error) && error.name === 'ApiV2AuthenticationError';
};
