import { ArticleSimple } from '@components/Article';

type Props = {
  articles: any[];
  className?: string;
};

const ArticleSimpleList = ({ articles, className }: Props) => {

  const isEmpty = articles.length === 0

  return (
    <div
      className={`grid grid-cols-1 gap-10 md:grid-cols-2 ${className}`}
    >
      {!isEmpty &&
        articles.map((article: any, id: number) => {
          return (
            <div key={article?.slug}>
              <ArticleSimple testId={id} article={article} />
            </div>
          )
        })}
    </div>
  );
};

export default ArticleSimpleList;
