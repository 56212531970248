'use client';
import cn from 'classnames';
import { useState, useEffect, forwardRef } from 'react';

interface LoaderProps {
  global?: boolean;
  className?: string;
  delay?: number;
}

export default forwardRef<HTMLDivElement, LoaderProps>(function Loader(
  { global, className, delay },
  ref,
) {
  const [show, setShow] = useState(!delay);

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(delayTimeout);
    };
  }, []);

  if (!show) {
    return <></>;
  }

  return (
    <div
      className={cn(
        'flex h-24 items-center justify-center',
        { 'fixed left-0 top-0 z-50 h-full w-full bg-black/50': global },
        className,
      )}
      ref={ref}
    >
      <div className="relative h-[73px] w-[57px]">
        <BackgroundSVG />
        <div className="absolute left-3 top-[50px] mx-auto h-[10px] w-[10px] animate-ping-2 rounded-full bg-[#ef796d] animation-delay-300" />
        <div className="absolute left-[38px] top-[50px] mx-auto h-[10px] w-[10px] animate-ping-2 rounded-full bg-[#6ec6e9] animation-delay-600" />
      </div>
    </div>
  );
});

const BackgroundSVG = () => {
  return (
    <svg
      version="1.1"
      id="Warstwa_2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="59px"
      height="73px"
      viewBox="0 0 59 73"
      enableBackground="new 0 0 59 73"
      xmlSpace="preserve"
    >
      <path
        fill="none"
        stroke="#adbfdb"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        d="M51.827,63.775c0,1.359-1.104,2.464-2.464,2.464
H10.574c-1.359,0-2.462-1.104-2.462-2.464v-7.793l-2.316-2.459V6.555c0-1.36,1.102-2.462,2.462-2.462h42.551
c1.362,0,2.462,1.102,2.462,2.462v46.957l-1.444,2.462V63.775z"
      />
      <line
        fill="none"
        stroke="#adbfdb"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        x1="1.333"
        y1="31.788"
        x2="1.333"
        y2="38.735"
      />
      <line
        fill="none"
        stroke="#adbfdb"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        x1="57.711"
        y1="31.788"
        x2="57.711"
        y2="38.735"
      />
      <line
        fill="none"
        stroke="#adbfdb"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        x1="25.553"
        y1="59.094"
        x2="34.478"
        y2="59.094"
      />
      <path
        fill="none"
        stroke="#adbfdb"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        d="M19.118,66.239v2.899c0,0-0.054,2.986-3.944,2.986
c0,0-3.963,0-3.963-2.681v-3.205"
      />
      <path
        fill="none"
        stroke="#adbfdb"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        d="M48.449,66.239v2.899c0,0-0.056,2.986-3.947,2.986
c0,0-3.961,0-3.961-2.681v-3.205"
      />
      <path
        fill="none"
        stroke="#adbfdb"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        d="M48.449,40.786c0,1.359-1.05,2.459-2.344,2.459
H13.878c-1.293,0-2.342-1.1-2.342-2.459v-7.887c0-1.359,1.049-2.462,2.342-2.462h32.227c1.294,0,2.344,1.103,2.344,2.462V40.786z"
      />
      <line
        fill="none"
        stroke="#adbfdb"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        x1="11.536"
        y1="25.587"
        x2="48.449"
        y2="25.587"
      />
      <line
        fill="none"
        stroke="#adbfdb"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        x1="22.682"
        y1="43.245"
        x2="31.659"
        y2="38.325"
      />
      <line
        fill="none"
        stroke="#adbfdb"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        x1="35.795"
        y1="43.245"
        x2="44.768"
        y2="38.325"
      />
    </svg>
  );
};
