// TODO: Rewrite maybe?
export const redirectToLinkedInLogin = (redirectTo?: string) => {
  const params = [
    'redirect_uri=' + (redirectTo || window.location.origin),
    'client_id=' + process.env.NEXT_PUBLIC_AUTH_LINKEDIN_CLIENTID,
    'response_type=code',
    'state=linkedin' + window.location.pathname,
    'scope=openid%20profile%20email',
  ];

  window.location.href =
    'https://www.linkedin.com/oauth/v2/authorization?' + params.join('&');
};

export const redirectToTransIdLogin = (redirectTo?: string) => {
  const params = [
    'redirect_uri=' + ((redirectTo || window.location.origin) + '/'),
    'client_id=' + process.env.NEXT_PUBLIC_AUTH_ONELOGIN_CLIENTID,
    'response_type=code',
    'scope=companies.employees.me.read',
    'state=onelogin',
  ];
  window.location.href =
    process.env.NEXT_PUBLIC_AUTH_ONELOGIN_URL + '?' + params.join('&');
};
