'use client';
import { useState, useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';

import Loader from '../root/Loader';

const LazyComponent = ({ children }) => {
  const lazyLoader = useRef(null);
  const intersection = useIntersection(lazyLoader, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });
  const [showed, onChange] = useState(false);

  useEffect(() => {
    if (intersection?.isIntersecting) {
      onChange(intersection?.isIntersecting || false);
    }
  }, [intersection?.isIntersecting]);

  return <div ref={lazyLoader}>{showed ? children : <Loader />}</div>;
};

export default LazyComponent;
